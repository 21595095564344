/* You can add global styles to this file, and also import other style files */
//@import "~swiper/swiper-bundle";
//@import "~nouislider/dist/nouislider.min.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~swiper/swiper.min.css";

.popup-modal .ant-modal-content {
  z-index: 10000 !important;
  background-color: transparent;
  border-radius: 30px;
  box-shadow: none !important;

  & img {
    border-radius: 30px;
  }

  .footer {
    display: none;
  }
}

.dark-mode tspan {
  fill: #888888;

  &:first-child {
    fill: #ffffff;
  }
}

.top-20 {
  margin-top: -15px !important;
}

:root .menu-open app-apphomelayout {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: block;
}

:root {
  .modal-backdrop {
    //z-index:8;
  }
}

.footer {
  z-index: 2000;
}

// full calendar
.fc {
  border-radius: 5px;
  overflow: hidden;

  a {
    text-decoration: none;
  }

  .fc-toolbar-title {
    margin: 0 !important;
    font-size: 16px !important;
  }

  .fc-button {
    padding: 0.275em 0.475em;
    font-size: 0.95em;
    line-height: 1.7;
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0em !important;
    padding: 10px 15px !important;

    .fc-button {
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  &.fc-theme-standard {
    table {
      border: 0 none;
      border-collapse: collapse;

      th {
        font-size: 14px;
        text-decoration: none;
        padding: 10px 0;
        line-height: 20px;
        border: none;

        a.fc-col-header-cell-cushion {
          font-weight: normal;
        }
      }

      td {
        border: none;
      }

      table {
        td {
          background-color: transparent;
          color: inherit;
          font-size: 14px;
          text-decoration: none;
          padding: 0;

          a {
            color: inherit;
            font-weight: normal;
          }

          .fc-daygrid-more-link {
            font-size: 10px;
          }

          .fc-daygrid-day-events {
            margin-top: -8px;
          }

          &:first-child {
            border-left: none;
          }

          &:last-child {
            border-right: none;
          }
        }

        tr:first-child {
          td {
            border-bottom: none;
          }
        }

        tr:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
}

.font-weight-bold {
  font-weight: bold;
}

.desactive {
  bottom: -1000px;
  transition: 1s ease-out;
}

.remove-bg {
  filter: brightness(1.1);
  mix-blend-mode: multiply;
}

@media screen and (max-width: 480px) {
  .fc.fc-theme-standard table table td .fc-daygrid-more-link {
    height: 8px;
    width: 8px;
    border-radius: 5px;
    text-indent: 15px;
    overflow: hidden;
    display: block;
  }
}

.fc .fc-timegrid-col.fc-day-today,
.fc .fc-day.fc-day-future {
  background-color: #fff;
}

.fc .fc-timegrid-col-bg .fc-non-business {
  // z-index: 1;
  background-image: linear-gradient(
    45deg,
    #ff738d 25%,
    #f5f5f5 25%,
    #f5f5f5 50%,
    #ff738d 50%,
    #ff738d 75%,
    #f5f5f5 75%,
    #f5f5f5 100%
  );
  background-size: 84.85px 84.85px;
  cursor: not-allowed;
}

.fc .fc-timegrid-slot {
  border-bottom: 0;
  height: 2.7em;
}

.fc-v-event .fc-event-main-frame {
  display: flex;
  margin: 0 auto;
  text-align: center;
  height: 100%;
  align-items: center;
  flex-direction: row-reverse;
}

.past-slot {
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 100px,
    #568afd 100px,
    #568afd 200px
  );
  background-color: #26bad8;
}
